import {$} from '../common/variables'
// import { transitionBarbajs } from './barbajs-transitions/transition-fade'
import {transitionBarbajs} from './barbajs-transitions/transition-fromBottom'

const loadJS = id => {
  const head = document.getElementsByTagName('head')[0]
  const oldScript = document.getElementById(id)
  const newScript = document.createElement('script')
  newScript.setAttribute('id', id)
  newScript.src = oldScript.src
  oldScript.remove()
  head.appendChild(newScript)
}

const barbaInit = () => {
  const barbaWrapper = $('[data-barba="wrapper"]')
  if (barbaWrapper) {
    barba.init({
      prevent: ({el}) => el.classList && (el.classList.contains('ab-item') || el.classList.contains('no-barbajs')),
      timeout: 10000, // 4 - 10 à cause du VPN
      transitions: [transitionBarbajs],
    })

    barba.hooks.afterLeave(({current, next}) => {
      $('.slider__wrapper').slick('unslick')
    })
    barba.hooks.enter(({current, next}) => {
      //console.log('Hook : beforeEnter')
      window.scrollTo(0, 0)

      setTimeout(function() {
        window.initScripts()
        // Ajout des classes sur le body
        if (current.container) {
          let nextHtml = next.html
          let response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
          let bodyClasses = $(response)
            .filter('notbody')
            .attr('class')
          $('body').attr('class', bodyClasses)
        }
      }, 100)
    })
  }
}

export {barbaInit}

import {cl, minWidth} from './common/functions'
import {$, __, variables} from './common/variables'
import {myAjaxFunction} from './components/ajax-example'
import {aosInit} from './components/aos'
import {barbaInit} from './components/barba'
import {getCSSVariables} from './components/css-variables'
import {faqToggle} from './components/faq'
import {fixedHeader} from './components/fixedHeader'
import {showForm} from './components/form'
import inputLabels from './components/inputLabels'
import {packMenu} from './components/pack-menu'
import {scrollToAnchor} from './components/scrollToAnchor'
import {slickInit} from './components/slick'
// import {blockStyles} from './components/block-styles'

/*
 * Get all Breakpoints defined in css variables
 * */
const cssBreakpoints = getCSSVariables('breakpoint')
;[...cssBreakpoints].forEach(breakpoint => {
  const breakpointPrefix = breakpoint[0].split('-').pop()
  variables.breakpoints[breakpointPrefix] = breakpoint[1]
})

if ($('[data-barba="wrapper"]').length) {
  barbaInit()
}

window.initScripts = () => {
  // console.log('initialisation des scripts !!!')

  myAjaxFunction()

  fixedHeader()

  scrollToAnchor()

  faqToggle()

  aosInit()

  packMenu()

  showForm()

  inputLabels()

  slickInit()

  // blockStyles()
}
window.initScripts()

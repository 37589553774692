import {$, variables} from '../common/variables'
import {minWidth} from '../common/functions'

const showForm = () => {
  if ($('.js-gform-content').length) {
    const formId = $('.js-gform-content').attr('data-form')
    $.get(variables.ajaxURL + '?action=load_gravity_form&form=' + formId, function(data) {
      $('.js-gform-content').html(data)
    })
  }

  $('.js-showForm').click(function() {
    $('.form__register').addClass('show')

    if (!minWidth('xl')) {
      $('body').addClass('modal-open')
    }
  })

  $('.js-closeForm').click(function() {
    $(this)
      .closest('.form__register')
      .removeClass('show')
    $('body').removeClass('modal-open')
  })

  const moveSubmitButton = () => {
    const $gformButton = $('#gform_1 .gform_button')
    $gformButton.prependTo('#field_1_19').wrap('<div class="gform_buttonWrapper"></div>')

    const svg = '<svg class="transition-loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve"><path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform></path></svg>'

    $gformButton.closest('.gform_buttonWrapper').prepend('<div class="button-loader">' + svg + '</div>')

    $('#gform_1 .gform_button').click(function() {
      $(this).blur()
      $(this).val('')
      $(this)
        .siblings('.button-loader')
        .show()
    })
  }
  moveSubmitButton()

  $(document).on('gform_post_render', function() {
    moveSubmitButton()
  })
}

export {showForm}

import {$, variables} from '../common/variables'

const slickInit = () => {
  //console.log('🟢 SlickInit Ready !')

  const homeSlider = $('.slider__wrapper')

  homeSlider.slick({
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    speed: 800,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: false,
    lazyLoad: 'ondemand',
    // appendDots: $('.slider__slide .slide__left')
  })

  homeSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    const slickCurrent = $('.slick-current')
    const slickNext = $('[data-slick-index=' + nextSlide + ']')

    slickCurrent.addClass('closed')
    $('.slick-current .slide__left--inner').addClass('leaving')

    if (slickCurrent.hasClass('slider__slide--odd')) {
      slickNext.removeClass('slider__slide--odd')
      slickNext.addClass('slider__slide--even')
    } else {
      slickNext.removeClass('slider__slide--even')
      slickNext.addClass('slider__slide--odd')
    }
  })

  homeSlider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
    $('.slick-current').removeClass('closed')
    $('.slick-slide .slide__left--inner').removeClass('leaving')
  })
}

export {slickInit}

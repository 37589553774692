import {$} from '../common/variables'

const inputLabels = () => {
  //console.log('inputLabels init')

  const inputValueChecker = () => {
    $('.gform_wrapper input[type="text"], .gform_wrapper input[type="tel"], .gform_wrapper input[type="date"], .gform_wrapper input[type="email"], .gform_wrapper input[type="number"], .gform_wrapper input[type="password"], .gform_wrapper input[type="url"]').each(function() {
      if ($(this).val() !== '') {
        $(this)
          .closest('.gfield')
          .addClass('gfield--focused')
      }
    })
  }

  const inputFocusBlur = () => {
    $('.gform_wrapper').on('focus', 'input[type="text"],input[type="tel"],input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="url"]', function(e) {
      $(this)
        .closest('.gfield')
        .addClass('gfield--focused')
    })
    $('.gform_wrapper').on('blur', 'input[type="text"],input[type="tel"],input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="url"]', function(e) {
      if ($(this).val() === '') {
        $(this)
          .closest('.gfield')
          .removeClass('gfield--focused')
      }
    })
  }

  inputValueChecker()
  inputFocusBlur()

  $(document).on('gform_post_render', function() {
    inputValueChecker()
    inputFocusBlur()
  })
}

export default inputLabels

import {$, variables} from '../common/variables'

const faqToggle = () => {
  //console.log('🟢 faqToggleReady !')
  $('.faq__item').click(function() {
    const targetSlug = $(this).attr('data-faq')
    const pathName = window.location.pathname
    const pathSplit = pathName.split('/')
    const pageSlug = pathSplit[1]
    const currentURL = window.location.protocol + '//' + window.location.host

    const nextURL = currentURL + '/' + pageSlug + '/' + targetSlug + '/'
    const nextTitle = ''
    const nextState = {}

    if (pageSlug) {
      // This will create a new entry in the browser's history, without reloading
      window.history.replaceState(nextState, nextTitle, nextURL)
    }

    $('.faq__item')
      .find('.faq__item--answer')
      .stop()
      .slideUp()

    if ($(this).hasClass('opened')) {
      $(this).removeClass('opened')
      if (pageSlug) {
        window.history.replaceState(nextState, nextTitle, currentURL + '/' + pageSlug + '/')
      }
    } else {
      $('.faq__item').removeClass('opened')
      $(this).addClass('opened')
    }

    $(this)
      .find('.faq__item--answer')
      .stop()
      .slideToggle()
  })
}

export {faqToggle}

import {cl, isSameDomain, isStyleRule} from '../common/functions'
import {$, variables} from '../common/variables'

const getCSSVariables = valuetoSearch =>
  // styleSheets is array-like, so we convert it to an array.
  // Filter out any stylesheets not on this domain
  [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        // cssRules is array-like, so we convert it to an array
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map(propName => [propName.trim(), rule.style.getPropertyValue(propName).trim()])
            // Discard any props that don't start with "--". Custom props are required to.
            .filter(([propName]) => propName.indexOf(`--wphf-${valuetoSearch}`) === 0)

          return [...propValArr, ...props]
        }, [])
      ),
    []
  )

export {getCSSVariables}

import {$, variables} from '../common/variables'

const packMenu = () => {
  if (window.timerFade) {
    clearInterval(window.timerFade)
  }
  if (window.timerCards) {
    clearInterval(window.timerCards)
  }

  function showPackDetails(index) {
    //console.log('showPackDetails !!☮☮')
    const fadeInSpeed = 500
    const fadeOutSpeed = 200

    // clearInterval(window.timerFade)
    // clearInterval(window.timerCards)

    let targetIndex = index === undefined ? $(`.pack__slider--item.active`).attr('data-index') : index

    $(`.pack__slider--item`)
      .stop()
      .fadeOut(fadeOutSpeed)
    $(`.pack__slider--item`).removeClass('active')
    $(`.pack__slider--item`).removeClass('entering')
    $(`.pack__slider--item[data-index='${targetIndex}']`).addClass('entering')

    clearInterval(window.timerFade)
    window.timerFade = setTimeout(function() {
      $(`.pack__slider--item[data-index='${targetIndex}']`)
        .stop()
        .fadeIn(fadeInSpeed)

      $(`.pack__slider--item[data-index='${targetIndex}']`).addClass('active')
    }, fadeInSpeed)

    clearInterval(window.timerCards)
    window.timerCards = setInterval(function() {
      if ($('.pack__slider--item.active .card__item--wrapper').length > 1) {
        const $firstCard = $(`.pack__slider--item.active .card__item--wrapper[data-index="0"]`)
        const $currentCard = $('.pack__slider--item.active .card__item--wrapper.active')
        const $nextCard = $currentCard.next('.card__item--wrapper')

        if ($nextCard.length > 0) {
          $nextCard.addClass('active')
        } else {
          $firstCard.addClass('active')
        }
        $currentCard.removeClass('active')
      }
    }, 5000)
  }

  if ($('.card__item--wrapper').length > 0) {
    showPackDetails()
  }

  $('.pack__menu--item').click(function() {
    $('.pack__menu--item.active').removeClass('active')
    $(this).addClass('active')
    const targetIndex = $(this).attr('data-target')
    const targetSlug = $(this).attr('data-pack')

    const pathname = window.location.pathname
    const pathSplit = pathname.split('/')

    const nextURL = window.location.protocol + '//' + window.location.host + '/' + pathSplit[1] + '/' + targetSlug + '/'
    const nextTitle = ''
    const nextState = {}

    // This will create a new entry in the browser's history, without reloading
    window.history.replaceState(nextState, nextTitle, nextURL)

    showPackDetails(targetIndex)

    if (window.innerWidth < 768) {
      const $header = $('.site__header')
      const $headerHeight = $header.outerHeight()

      setTimeout(function() {
        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $('#pack-content').offset().top - $headerHeight - 20,
            },
            1000,
            'swing'
          )
      }, 500)
    }
  })
}

export {packMenu}

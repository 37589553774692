import {$, variables} from '../common/variables'

const scrollToAnchor = () => {
  //console.log('🟢 scrollToAnchorReady !')

  $("a[href*='#']:not([href='#']):not([data-fancybox])").click(function(e) {
    e.preventDefault()
    const hash = this.hash
    const section = $(hash)

    const $header = $('.site__header') // Le header
    const heightOffset = 0 // Offset pour créer un espace entre le header et l'ancre

    const $headerHeight = $header.outerHeight()

    if (hash && !document.body.classList.contains('wp-admin')) {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: section.offset().top - $headerHeight - heightOffset,
          },
          1000,
          'swing',
          function() {
            history.replaceState({}, '', hash)
          }
        )
    }
  })
}

export {scrollToAnchor}

import {$, variables} from '../common/variables'

const fixedHeader = () => {
  $(window).scroll(function() {
    var $header = $('.site__header'),
      scroll = $(window).scrollTop()

    if (scroll >= 200) $header.addClass('fixed')
    else $header.removeClass('fixed')
  })
}

export {fixedHeader}

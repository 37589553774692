import {$, variables} from './variables'

const cl = e => {
  console.log(e)
}
const minWidth = value => {
  return window.matchMedia(`(min-width: ${variables.breakpoints[value]})`).matches
}

const ajax = (params, successFunction, errorFunction, ajaxType) => {
  const url = new URL(variables.ajaxURL) // In wordpress the url is defined in the function.php
  if (window.fetch) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    // prettier-ignore
    return fetch(url, {
      headers: {
        'Content-Type': ajaxType === 'json'
          ? 'application/json; charset=utf-8'
          : ajaxType === 'text'
            ? 'charset=utf-8'
            : undefined
      }
    })
      .then(
        response => ajaxType === 'json'
          ? response.json()
          : ajaxType === 'text'
            ? response.text()
            : undefined
      )
      .then(successFunction)
      .catch(errorFunction);
  } else {
    if (window.jQuery) {
      // prettier-ignore
      return $.ajax({ //Backup JQuery sur IE
        dataType: ajaxType === 'json'
          ? 'json'
          : ajaxType === 'text'
            ? 'html'
            : undefined,
        url: url,
        data: params,
        success: successFunction,
        error: errorFunction
      });
    }
  }
}

// Check if the stylesheet is internal or hosted on the current domain.
const isSameDomain = styleSheet => {
  // Internal style blocks won't have an href value
  if (!styleSheet.href) {
    return true
  }
  return styleSheet.href.indexOf(window.location.origin) === 0
}

// Determine if the given rule is a CSSStyleRule
const isStyleRule = rule => rule.type === 1

export {cl, minWidth, ajax, isSameDomain, isStyleRule}

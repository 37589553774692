import {$} from '../../common/variables'

const delay = n => {
  n = n || 2000
  return new Promise(done => {
    setTimeout(() => {
      done()
    }, n)
  })
}

const time = 900

const transitionBarbajs = {
  name: 'default-transition',
  // sync: true,
  async leave(data) {
    const done = this.async()
    $('.transition-layers').addClass('active')
    await delay(time)
    done()
  },
  async enter(data) {
    setTimeout(function() {
      $('.transition-layers').removeClass('active')
    }, 100)
  },
  async once(data) {
    transitionHtmlCss()
  },
}

const transitionHtmlCss = () => {
  $('body').prepend(`<style>.transition-layers .layer-1,
.transition-layers .layer-2,
.transition-layers .layer-3 {
  position: fixed;
  top: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8000;
  transition: top 900ms cubic-bezier(0.77, 0, 0.175, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.transition-layers.active .layer-1,
.transition-layers.active .layer-2,
.transition-layers.active .layer-3 {
  top: 0;
}
.transition-layers.active .layer-1 {
  transition-delay: 0ms;
}
.transition-layers.active .layer-2 {
  transition-delay: 100ms;
}
.transition-layers.active .layer-3 {
  transition-delay: 200ms;
}
.transition-layers.active .transition-loader {
  opacity: 1;
  transition-delay: 500ms;
}
.transition-layers .layer-1 {
  background-color: var(--wphf-third);
  transition-delay: 200ms;
}
.transition-layers .layer-2 {
  transition-delay: 100ms;
  background-color: var(--wphf-secondary);
}
.transition-layers .layer-3 {
  transition-delay: 0ms;
  background-color: var(--wphf-primary);
}
.transition-layers .transition-loader {
  width: 100px;
  height: 100px;
  opacity: 0;
  transition-delay: 0ms;
  transition: opacity 600ms cubic-bezier(0.77, 0, 0.175, 1);
}</style><div class="transition-layers">
                      <div class="transition-layer layer-1"></div>
                      <div class="transition-layer layer-2"></div>
                      <div class="transition-layer layer-3">
                          <svg class="transition-loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                              <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                  <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
                              </path>
                          </svg>
                      </div>
                  </div>`)
}

export {transitionBarbajs}

import {$, variables} from '../../../../glanum/assets/js/common/variables'

const aosInit = () => {
  const animations = document.querySelectorAll('.aos-init')
  if (!!animations.length) {
    for (const animation of animations) {
      animation.setAttribute('data-aos', 'fade-up')
      animation.setAttribute('data-aos-offset', '400')
      animation.setAttribute('data-aos-delay', '50')
      animation.setAttribute('data-aos-duration', '1000')
      animation.setAttribute('data-aos-easing', 'ease-in-out')
      animation.setAttribute('data-aos-mirror', 'true')
      animation.setAttribute('data-aos-once', 'false')
      animation.setAttribute('data-aos-anchor-placement', 'top-bottom')
    }
  }
}

export {aosInit}

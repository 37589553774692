import {cl} from '../common/functions'
import {$, variables} from '../common/variables'

const myAjaxFunction = () => {
  const success = response => {
    cl(response)
    cl('ayaya')
  }

  const error = err => {
    cl(err)
  }

  const myElement = document.querySelector('.ayaya')

  !!myElement &&
    myElement.addEventListener('click', e => {
      e.preventDefault()

      // Parameters to pass
      const params = {
        action: `${variables.prefix}_ajax_example`, //The name of the function called
        id: 1,
      }

      ajax(params, success, error, 'json')
    })
}

export {myAjaxFunction}
